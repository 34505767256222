import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default class Bloglist extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    console.log(this.props.pageContext);
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()

    return (
      <Layout>
        <div className="blog-main">
          <div className="blog-inner">
            <h1 css={css`
            margin-top: 50px;
          `}>Alla artiklar</h1>
            {posts.map(({ node }) => {
              //                    const title = node.frontmatter.title || node.fields.slug
              //                    return <div key={node.fields.slug}>{title}</div>
              return (
                <div key={node.id}>
                  <Link
                    to={node.fields.slug}
                    css={css`
                            text-decoration: none;
                            color: inherit;
                        `}
                  >
                    <h3
                      css={css`
                margin-bottom: ${rhythm(1 / 4)};
              `}
                    >
                      {node.frontmatter.title}{" "}
                    </h3>
                    <p>{node.excerpt}</p>
                  </Link>
                </div>);
            })}
            {!isFirst && (
              <Link to={prevPage} rel="previous"> Föregående sida</Link>
            )}
            {!isLast && (
              <Link to={nextPage} rel="next"> Nästa sida </Link>
            )}
            <br></br>
            {numPages > 1 && Array.from({ length: numPages }, (_, i) => (
              <Link key={`pagination-number${i + 1}`} to={`/blog${i === 0 ? "" : "/" + (i + 1)}`}>
                &nbsp;{i + 1}&nbsp;
              </Link>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
          id
        }
      }
    }
  }
`
